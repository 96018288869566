import debounce from 'debounce';
import { filter } from 'lodash-es';

import httpService from '@/services/httpService';
import quoteService from '@/services/quoteService';

export default {
	name: 'location',
	data: function() {
		return {
			error: false,
			error_msg: '',
			selected: false,
			searching: false,
			extended_search: false,
			location: '',
			locations: [],
			selectedCity: {},
			focused_item: {},
			focused_index: -1,
			ii: 0,
			elems: [],
		};
	},
	props: ['type', 'placeholder', 'onSelect'],
	computed: {
		locationLastWordIndex: function() {
			return this.location.lastIndexOf(' ');
		},
	},
	mounted: function() {
		this.checkForQuoteData();
		this.searchLocation = debounce(this.searchLocation, 300);
		this.$nextTick(() => {
			// document.getElementById('location-tf').focus();
			this.$refs['location-tf'].focus();
		});
	},
	methods: {
		checkForQuoteData: function() {
			const quote = quoteService.getQuote();

			if (this.type === 'origin' && quote.origin_city) {
				this.selectedCity = {
					city: quote.origin_city,
					state: quote.origin_state,
					zipcode: quote.origin_zip,
					value: quote.origin_city + ', ' + quote.origin_state,
				};

				this.location = this.selectedCity.value;
				this.selected = true;
			}

			if (this.type === 'destination' && quote.destination_city) {
				this.selectedCity = {
					city: quote.destination_city,
					state: quote.destination_state,
					zipcode: quote.destination_zip,
					value:
						quote.destination_city + ', ' + quote.destination_state,
				};

				this.location = this.selectedCity.value;
				this.selected = true;
			}
		},
		searchLocation: function() {
			this.error = false;
			this.error_msg = 'Choose a location: ';
			this.selected = false;

			if (this.location && this.location.length >= 2) {
				this.extended_search = this.isExtendedSearchNeeded();
				const location = this.extended_search
					? this.location.substring(0, this.locationLastWordIndex)
					: this.location;
				let url = 'service/location?query=' + location.substr(0, 25);

				url += this.extended_search ? '&extended=1' : '';

				this.searching = true;

				httpService.get(url).then(
					data => {
						this.searching = false;
						this.locations = data;

						if (!this.locations.length) {
							this.error = true;
							this.error_msg = 'No locations found';

							return false;
						}

						if (this.extended_search) {
							this.matchSingleLocation(this.location.substr(this.locationLastWordIndex + 1));
						} else if (!isNaN(this.location)) {
							this.matchSingleLocation(this.location);
						} else if (this.locations.length === 1) {
							this.selectCity(this.locations[0]);
						}
					},
					errorResp => {
						this.searching = false;
						window.console.error(errorResp);
						this.error = true;
						this.error_msg = 'Something went wrong. Please try again.';
						// this.$toastr('error', 'Something went wrong. Please try again.', 'Error!')
					}
				);
			} else {
				this.$parent.onCitySelect(true, '');
				this.locations = [];
			}
		},
		isExtendedSearchNeeded: function() {
			let extended_search = false;
			const location = this.location.split(' ');

			if (location.length > 1) {
				extended_search = !isNaN(
					parseInt(location[location.length - 1])
				)
					? true
					: false;
			}

			return extended_search;
		},
		matchSingleLocation: function(searchValue) {
			const isZipcode = !isNaN(searchValue);
			const searchLocation = searchValue
				.replace(/[.,]+/, '')
				.replace(/\s\s+/, ' ')
				.toLowerCase();

			this.$nextTick(() => {
				// Get all locations that match the given location
				const matches
					= filter(
						this.locations,
						loc => {
							if (!loc) {
								return false;
							}

							let location = '';

							if (isZipcode) {
								location = loc.zipcode;
							} else {
								location = loc.value
									.replace(/[.,]+/, '')
									.toLowerCase();
							}

							if (!location) {
								return false;
							}

							// Zipcode needs to be a string in case it starts with '0'
							return location.includes(String(searchLocation));
						}
					) || [];

				if (!matches.length) {
					return;
				}

				const autoSelect = (!this.extended_search && isZipcode && matches.length <= 3) || matches.length === 1;

				this.$log.debug(
					'matchSingleLocation | ',
					searchLocation,
					this.locations,
					matches,
					this.extended_search,
					autoSelect
				);


				// Select the first location from dropdown if only three locations
				// were found with the given zipcode
				if (autoSelect) {
					this.selectCity(matches[0]);
				}
			});
		},
		selectCity: function(item) {
			this.selectedCity = item;

			if (!this.selectedCity) {
				return false;
			}

			this.location = this.selectedCity.value;
			this.selected = true;

			if (this.type === 'origin') {
				quoteService.setOrigin(this.selectedCity);
			} else {
				quoteService.setDestination(this.selectedCity);
			}

			setTimeout(() => {
				this.citySelected();
				this.cleaner();
			}, 1000);
		},
		citySelected: function() {
			const locationType = this.type === 'origin' ? 'pickup' : 'delivery';

			if (this.selected) {
				// this.onSelect();    figure out why its not working
				this.error = false;
			} else {
				this.error = true;

				if (!this.error_msg.length || !this.location.length) {
					this.error_msg = `Begin by entering the city or zipcode of your ${locationType} location`;
				}
			}

			this.$parent.onCitySelect(
				this.error,
				this.selectedCity.zipcode ? this.selectedCity : this.location,
				this.locations
			);
		},
		showError: function() {
			const ele = document.getElementById('locations_dd');

			if (ele) {
				ele.scrollTop = 0;
			}
		},
		keyPress: function(event) {
			if (!this.locations || !this.locations.length) {
				return false;
			}

			const dropDown = document.getElementById('locations_dd');

			if ((event.keyCode === 40 || event.keyCode === 38) && this.ii < 1) {
				this.elems = document.getElementsByClassName('dropdown-item');
				document.activeElement.blur();
				setTimeout(function() {
					dropDown.focus();
				}, 40);
				this.ii++;
			}

			if (event.keyCode === 40) {
				const i
					= this.focused_index < this.locations.length - 1
						? ++this.focused_index
						: this.focused_index;

				dropDown.scrollTop = this.elems[i + 1].scrollHeight * i;
				this.focused_item = this.locations[i];
			} else if (event.keyCode === 38) {
				const j
					= this.focused_index > 0
						? --this.focused_index
						: this.focused_index;

				dropDown.scrollTop = this.elems[j + 1].scrollHeight * j;
				this.focused_item = this.locations[j];
			} else if (event.keyCode === 13 && this.focused_item) {
				this.selectCity(this.focused_item);
			}
		},
		cleaner: function() {
			this.focused_index = -1;
			this.focused_item = {};
			this.elems = [];
		},
		isZipCode: function() {
			if (this.location && this.location.match(/^[0-9]+$/) != null) {
				return true;
			}

			return false;
		},
	},
};
